import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { AppBar, Toolbar, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logoSnoop from '../../images/logo-snoop.png';
import {Typography} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { animateScroll as scroll} from 'react-scroll';
import { scroller } from 'react-scroll';
import{
    ROUTES
} from '../../constants/router';

const useStyles =  makeStyles((theme) => ({
    appbar: {
        backgroundColor: '#161616',
        position: 'fixed'
    },
    toolbar: {
        // display: 'flex',
        // justifyContent: 'left',
        width: '100%'
    },
    logo:{
        marginRight:50,
        height: 35
    },
    esloganInpur:{   
        right: 0,
        color: "#D2D2D2",        lineHeight: 1.47059,
        fontWeight: 100,
        letterSpacing: ".0522em",
    }
}));

const Header = () => {

    const classes = useStyles();
    const handleScrollTop = ()=>{
        scroll.scrollToTop();
    }
    const handleScrollTo = (name) =>{
        const scrollType = {
            duration: 500,
            delay: 50,
            smooth: true, // linear “easeInQuint” “easeOutCubic” 
            offset: -100,
         };
         
        scroller.scrollTo((name).toString(), scrollType);
    }
    return(
        <AppBar position="static" className={ classes.appbar }>
           
            {/* <Toolbar className={ classes.toolbar }> */}
                <Box display="flex" p={1}>
                    <Box p={1} flexGrow={1}>
                        <img src={logoSnoop} className={classes.logo} alt={'Inpur'}></img> 
                        
                    </Box>
                    <Box p={1} flexGrow={1}>
                        <Button color="inherit" onClick={handleScrollTop} >Inicio</Button>
                    </Box>
                    {
                        ROUTES.map((route,index)=>{
                            return (<Box key={`box-${index}`} p={1} flexGrow={1}>
                                        <Button key={`button-${index}`} color="inherit" onClick={()=>{handleScrollTo(index.toString())}} >{route.LABEL}</Button>
                                    </Box>)
                        })
                    }
                    <Box p={1.5}>
                        <Typography variant="h6" className={classes.esloganInpur}>Maxima Aislación, Minimo Impacto</Typography>
                    </Box>
                </Box>
                
                
                
            {/* </Toolbar> */}
        </AppBar>
    )
}

export default Header;
