import React from 'react'
import {Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    QueText:{
        fontWeight: 600,
        letterSpacing: "-.002em",
        align: "center"
    },
    TextoPur:{
        
        marginTop: theme.spacing(2),
        lineHeight: 1.47059,
        fontWeight: 400,
        letterSpacing: "-.022em",
    }
}));

export default function Encabezado() {
    const classes = useStyles();
    return (
        /* <Typography variant="h2" className={classes.QueText}>Que es ?</Typography> 
             
             <Typography variant="h6" className={classes.TextoPur}>
               Se trata de la mezcla de dos componentes quimicos en estado liquido y a temperatura ambiente,
                donde se produce una reacción exotermica, que da como resultado una estructura sólida, uniforme y muy resistente.

            </Typography> */
        <Typography variant="h6" align="center" className={classes.TextoPur}>
             La espuma rígida de POLIURETANO para aislamiento se obtiene por la mezcla de reacción de dos componentes líquidos: poliol e isocianato. Mediante sucesivas pasadas, se logra el espesor final deseado, hasta obtener una "piel" resistente que asegura una máxima aislación y hermeticidad sin juntas. Una solución de bajo costo en mano de obra y de excelente calidad térmica para cualquier problema de aislamiento que pueda plantearse.
        </Typography>
    )
}
