
import tumb1 from '../images/Porfolio/Bosinga.png';
import tumb2 from '../images/Porfolio/tumb2.png';
import tumb3 from '../images/Porfolio/tumb3.png';
import tumb4 from '../images/Porfolio/tumb4.png';
import tumb5 from '../images/Porfolio/tumb5.png';
import tumb6 from '../images/Porfolio/tumb6.png';




export const PORFOLIO = Object.freeze({
    LIST:[
        {
            image: tumb1,
            title: "",
            text: ""
        },
        {
            image: tumb2,
            title: "",
            text: ""
        },
        {
            image: tumb3,
            title: "",
            text: ""
        },
        {
            image: tumb4,
            title: "",
            text: ""
        },
        {
            image: tumb5,
            title: "",
            text: ""
        },
        {
            image: tumb6,
            title: "",
            text: ""
        },
    ]
})