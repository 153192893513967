import React from 'react'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import {BENEFICIOS} from '../../../../constants/beneficios.js';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    paper: {
      height: 150,
      width: 150,
    },
    control: {
      padding: theme.spacing(2),
    },
    beneficioContainer:{
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(0),
    },
    imgBeneficio:{
        marginBottom: theme.spacing(2)
    },
    txtBeneficio:{
        padding: theme.spacing(2),
        fontSize: 16
    },
    Titulo:{
        fontWeight: 600,
        letterSpacing: "-.002em",
        paddingTop: theme.spacing(4)
    }
}));

export default function ListaBeneficios() {
    const classes = useStyles();
    return (
        <div>
            <Typography variant="h2" align="center" className={classes.Titulo}>Beneficios</Typography> 
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12} >
                    <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                        {BENEFICIOS.LIST.map((value) => (
                            <Grid key={value} item md={12}>
                                <Grid container className={classes.beneficioContainer}>
                                    <Grid item md={2} xs={12} className={classes.imgBeneficio} align="center">
                                        <img className={classes.paper} src={value.image}></img>
                                    </Grid>
                                    <Grid item md={10} xs={12}>
                                        <Typography variant="h6" className={classes.txtBeneficio} >
                                            <div dangerouslySetInnerHTML={{ __html: value.text }} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
