import React from 'react';
import Home from '../containers/home';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';


const theme = createMuiTheme({
    palette: {
      type: 'dark', // Switching the dark mode on is a single property value change.
    },
    typography: { useNextVariants: true },
  });

const RouterApp = ({location}) => {
    const currentKey = location? (location.pathname.split("/")[1] || "/"):"";
	const timeout = { enter: 10, exit: 500 };

    return (
        <MuiThemeProvider theme={theme}>
            <div>
                
                <TransitionGroup className="transition-group"> 
                    <CSSTransition
                        key={currentKey}
                        timeout={timeout}
                        classNames="fade"
                        appear
                    >
                        <Home></Home>
                        
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </MuiThemeProvider>
    );
};

export default RouterApp;