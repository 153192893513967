import React from 'react';
import { Parallax } from "react-parallax";
import image1 from '../../images/PortadaInpur.png';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(14),
      paddingTop: theme.spacing(16),
      paddingRight: 0,
    },
  },
}));




export default function ImagePresentation() {
    const classes = useStyles();
    return (
      
        <Paper className={classes.mainFeaturedPost} >
          <Parallax bgImage={image1} strength={200}>
            <div className={classes.overlay} />
              <Grid container>
                  <Grid item md={6}>
                  <div className={classes.mainFeaturedPostContent}>
                      <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                      Poliuretano Proyectado
                      </Typography>

                  </div>
              </Grid>
            </Grid>
          </Parallax>
      </Paper>
    
    )
}
