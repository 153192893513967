
import AislacionImg from '../images/Beneficios/termometro.png';
import GotaImg from '../images/Beneficios/gota.png';
import CasaVerdeImg from '../images/Beneficios/casa-verde.png';
import LamparaImg from '../images/Beneficios/lamparita.png';
import RecicladoImg from '../images/Beneficios/reciclado.png';
import SolImg from '../images/Beneficios/Sol.png';




export const BENEFICIOS = Object.freeze({
    LIST: [
        {
            image: AislacionImg,
            text: "El <b>aislamiento térmico</b> que ofrece el poliuretano permite disminuir el consumo de energía por aire acondicionado o calefacción, brindando una eficiencia superior en todo tipo de construcciones. Además, protege los materiales contra los cambios de temperatura. <br/> La espuma de poliuretano forma una estructura de pequeñas celdas que alojan en su interior el gas aislante. Esta composición le otorga una de las tasas más bajas de conductividad térmica, alcanzando los valores de aislamiento de la temperatura necesarios con el mínimo espesor y un sellado del sustrato."
        },
        {
            image: GotaImg,
            text: "Gracias a su estructura de celdas cerradas y su continuidad, el sistema de espuma rígida de poliuretano es <b>impermeable al paso de agua líquida</b>, evitando la humedad y las filtraciones de agua. Su permeabilidad al vapor ayuda a regular la humedad del ambiente y de ser necesario puede controlarse con alguna barrera de vapor. "
        },
        {
            image: SolImg,
            text: "Conseguir una buena aislación térmica e hidrófuga brinda un <b>estado de confort<b/> al interior de nuestro ambiente e <b>inhibe el desarrollo de microorganismos<b/> (hongos, bacterias)."
        },
        {
            image: LamparaImg,
            text: "Un adecuado aislamiento térmico permite <b>reducir el consumo de energía<b/> hasta en un 40% en las viviendas."
        },
        {
            image: RecicladoImg,
            text: "Efecto inmediato en la <b>reducción en las emisiones contaminantes<b/> de hasta el 80%."
        },
        {
            image: CasaVerdeImg,
            text: "La <b>envolvente sustentable<b/> aumenta la resistencia de los edificios, disminuye los recursos necesarios para su mantenimiento y optimiza su eficiencia energética. Su capacidad aislante no se pierde con el tiempo y puede aplicarse en fachadas de edificios residenciales o comerciales, nuevos o restaurados."
        },
    ]
})