import React from 'react'
import Encabezado from './encabezado';
import ListaBeneficios from './listaBeneficios';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(8),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(2),
        color: theme.palette.grey[800],
    }
}));

export default function Beneficios() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
             <Encabezado></Encabezado>
             <ListaBeneficios></ListaBeneficios>
        </div>
    )
}
