import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import {Enlaces} from '../../constants/Links';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#161616',
    // marginTop: theme.spacing(8),
	padding: theme.spacing(2),
    marginTop: theme.spacing.unit, // You might not need this now
    position: "fixed",
    bottom: 0,
	left: 0,
	width: "100% !important"
  },
  btn: {
	  color: '#D2D2D2',
	  cursor: 'pointer',
	  marginRight: theme.spacing(1)
  }
}));

export default function Footer() {
	const classes = useStyles();
	const GoIg = ()=>{
		window.open(Enlaces.IG, "_blank")
	}
	const GoFb = ()=>{
		window.open(Enlaces.FB, "_blank")
	}
	const GoWa = ()=>{
		window.open(Enlaces.WA, "_blank")
	}
	return (
		<footer className={classes.footer}>
			<InstagramIcon className={classes.btn} onClick={GoIg}></InstagramIcon>
			<FacebookIcon className={classes.btn} onClick={GoFb}></FacebookIcon>
			<WhatsAppIcon className={classes.btn} onClick={GoWa}></WhatsAppIcon>
		</footer>
	);
}

Footer.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
