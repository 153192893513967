import React, { Component } from 'react';
import ImagePresentation from '../../components/image-presentation';
import { Element } from 'react-scroll';
import {
    ROUTES
} from '../../constants/router';


import './home.css';

const ElementWrap = (props)=>{
    const {name, Item} = props; 
    return (<Element key={name+"Element"} name={name.toString()}>
                <Item></Item>
            </Element>);

}

class Home extends Component {
    render() {
        return (
            <div style={{marginTop: '60px'}}>
                <ImagePresentation></ImagePresentation>
                {
                    ROUTES.map((route,i)=>{
                        return  <ElementWrap key={`element${i}`} name={i} Item={route.COMPONENT}></ElementWrap>
                    })
                }
                
            </div>
        );
    }
}

export default (Home);