import Beneficios from "../containers/home/beneficios";
import Porfolio from '../containers/home/porfolio';
import Contacto from '../containers/home/contacto';

class route{
    constructor(label,component) {
        this.LABEL = label;
        this.COMPONENT = component;
      }
}


const BENEFICIOS = new route("Beneficios", Beneficios);
const PORFOLIO = new route("Porfolio", Porfolio);
const CONTACTO = new route("Contacto", Contacto);
export const ROUTES = [
    BENEFICIOS,
    PORFOLIO,
    CONTACTO
]