import React from 'react'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Parallax, Background } from "react-parallax";
import {Typography} from '@material-ui/core';
import {PORFOLIO} from '../../../constants/porfolio';

const useStyles = makeStyles((theme) => ({
    root:{
        padding: theme.spacing(8),
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0),
        color: theme.palette.grey[800],
    },
    porfolioContainer: {
        flexGrow: 1,
        padding: theme.spacing(5),
        marginBottom: theme.spacing(1)
    },
    paper: {
      height: 450,
      width: 450,
      top:-50
    },
    paralaxImg:{
        height: 300,
        width: 300,
        borderRadius: 4
    },
    Titulo:{
        fontWeight: 600,
        letterSpacing: "-.002em",
        paddingTop: theme.spacing(2)
    }
}));

export default function Porfolio() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography variant="h2" align="center" className={classes.Titulo}>Porfolio</Typography> 
            <Grid container className={classes.porfolioContainer} spacing={2}>
                <Grid item xs={12} >
                    <Grid container justify="center" spacing={2}>
                    {PORFOLIO.LIST.map((value,index) => (
                        <Grid key={`tmb-${index}`} item>
                            <Parallax
                                key={`paralax-${index}`}
                                className={classes.paralaxImg}
                                strength={250}>
                                    <Background key={`bg-${index}`} >
                                        <img key={`img-${index}`} className={classes.paper} src={value.image}/>
                                    </Background>
                                    
                                </Parallax>
                           
                        </Grid>
                    ))}
                    </Grid>
                </Grid>
            </Grid>
            
        </div>
    )
}
