import React from 'react'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(8),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(8),
        color: theme.palette.grey[800],
    },
    paper: {
        height: 150,
        width: 150,
    },
    control: {
      padding: theme.spacing(2),
    },
    beneficioContainer:{
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(0),
    },
    imgBeneficio:{
        marginBottom: theme.spacing(2)
    },
    txtBeneficio:{
        padding: theme.spacing(2),
    },
    iframe:{
        border: 0,
        borderRadius: 4
    },
    contentInfo:{
        paddingLeft: theme.spacing(2)
    },
    label:{
        fontWeight: 800,
        letterSpacing: "-.002em",
    },
    info:{
        letterSpacing: "-.002em",
    },
    Titulo:{
        fontWeight: 600,
        letterSpacing: "-.002em",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        
    }
}));

export default function Contacto() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography variant="h2" align="center" className={classes.Titulo}>Información</Typography> 
            <Grid container  spacing={3}>
                <Grid item xs={12} md={8} >
                    <iframe className={classes.iframe} src="https://www.google.com/maps/d/u/0/embed?mid=1uR2tPcker3amz851jKGz2WpepQ-pj-rt" width="100%" height="480"></iframe>
                </Grid>
                <Grid item className={classes.contentInfo} xs={12} md={4}>
                    <Typography variant="h6" className={classes.label}>Mail:</Typography> 
                    <Typography variant="h5" className={classes.info}>ventas@inpur.com.ar</Typography> 
                </Grid>
            </Grid>
        </div>
    )
}
